<template>
  <div id="app-base-file">
    <Message :uid="userid" :chatType="type" @sendM="sendM"  @updateInfos="updateInfos" @gotoGroup="gotoGroup"></Message>
  </div>
</template>
<script>
import storage from 'store2'
import { ipcApiRoute } from '@/api/main'
import Message  from '@/components/Message.vue'

export default {
  components:{
    Message
  },
  props: {
    uid: {
      type: String,
      default: ''
    },
    chatType:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userid:'',
      type:''
    };
  },
  watch: {
    uid: function (e) {
      this.userid = this.uid
    },
    chatType: function (e) {
      this.type = this.chatType
    },
  },
  mounted () {
    this.userid = this.uid
    this.type = this.chatType
  },
  methods: {
    sendM(e){
      this.$emit('sendM',e)
    },
    updateInfos(e){
      this.$emit('updateInfos',e)
    },
    gotoGroup(e){
      this.$emit('gotoGroup',e)
    }
  }
}; 
</script>
<style lang="less" scoped>
#app-base-file {
  width: 100%;
  background-color: #fff;
  height: 100%;
}
</style>
